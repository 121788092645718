import { USER_STORAGE } from "../constants/api";

export const setUserAuth = (user) => {
  localStorage.setItem(USER_STORAGE, JSON.stringify(user));
};

export const getUser = () => {
  const user = localStorage.getItem(USER_STORAGE);
  if (!user || !Object.keys(user).length) return null;
  return user;
};

export const clearUser = () => {
  localStorage.removeItem(USER_STORAGE);
};

export const getAuthToken = () => {
  const user = getUser();
  return user ? JSON.parse(user)["user-token"] : "";
};

export const getUserRole = () => {
  const user = getUser();
  return user ? JSON.parse(user)["role"] : null;
};