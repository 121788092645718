import { ALERT_TYPE_SUCCESS } from "../../constants"

export const SuccessAlert = ({ successMsg = '' }) => {
    return (
        <div className="alert alert-success shadow-lg">
            <div>
                <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                <span>{successMsg || "Successful!"}</span>
            </div>
        </div>
    )
}

export const ErrorAlert = ({ errorMsg = '' }) => {
    return (
        <div className="alert alert-error shadow-lg">
            <div>
                <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                <span>{errorMsg || "Error! Something went wrong."}</span>
            </div>
        </div>

    )
}

const ToastMessage = (props) => {
    const { alert, closeToast } = props;

    const alertMsg = alert.message && alert.message || '';
    const alertType = alert.type && alert.type || '';

    if (!alertType) return;

    // Show alert based on alert / error type
    const toastAlert = alertType === ALERT_TYPE_SUCCESS ? <SuccessAlert successMsg={alertMsg} /> : <ErrorAlert errorMsg={alertMsg} />

    return (
        <div className="toast toast-top toast-end mt-20" onClick={closeToast}>
            {toastAlert}
        </div>
    )
}
export default ToastMessage
