import { Navigate, Outlet, useLocation } from "react-router-dom";
import { LOGIN_ROUTE } from "../constants/Routes";
import { getUser } from "../service/Auth";

export const RequireAuth = ({ children }) => {
  const location = useLocation();
  const user = getUser();

  if (!user) {
    return (
      <Navigate to={`${LOGIN_ROUTE}`} state={{ path: location.pathname }} />
    );
  }
  return (
    <>
      {children}
      <Outlet />
    </>
  );
};
