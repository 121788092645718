const ToggleButton = ({ data = {}, isEnable, onChange }) => {
    return (
        <div className="form-control flex justify-center align-middle">
            <label className="cursor-pointer label">
                <input
                    id={data.id}
                    name={data.title}
                    type="checkbox"
                    className="toggle toggle-accent"
                    checked={isEnable}
                    onChange={() => onChange(data)}
                />
            </label>
            <label className="text-xs font-extralight py-1">{data.title}</label>
        </div>
    )
}
export default ToggleButton