export const APP_NAME = "RINGS Stakeholders' Tool";

export const UPDATE_STATUS = "Pending";
export const SUBMIT_STATUS = "Submitted";

export const DISABLED_STATUS = "DISABLED";
export const ENABLED_STATUS = "ENABLED";

export const VIEW_ACTION = "View";
export const EDIT_ACTION = "edit";

// Feedback constants
export const BTN_START = "Start the evaluation";
export const BTN_CONTINUE = "Continue the evaluation";
export const BTN_EDIT = "Edit";

export const LBL_SUBMITTED = "Submitted";
export const LBL_NOT_STARTED = "Not yet started";
export const LBL_PARTIAL = "Partial";

export const BTN_NEWFEEDBACK_TITLE = "New stakeholders' map";
export const BTN_MAPPING_TITLE = "Your previous submissions";

export const ERROR_PRIVACY_POLICY = "Please accept the privacy policy to proceed.";

export const ADMIN_USER_ROLE = 'admin';
export const TEST_USER_ROLE = 'tester';

// Toast message alerts
export const ALERT_TYPE_SUCCESS = 'success';
export const ALERT_TYPE_ERROR = 'error';