import {
  answers_api,
  topics_api,
  update_answers_api,
  get_answer_api,
  category_api,
  groups_api,
  users_api,
  updateUser_api,
} from "../constants/api";
import { getAuthToken, setUserAuth } from "./Auth";

const PAGINATION = "?pageSize=100";

const validateResponse = (response) => {
  if (response.status === 401 || response.status === 302) {
    setUserAuth(null);
    //window.reload();
  }
  return response.json();
};

const getRequestHeader = () => {
  const headerObj = {
    "Content-Type": "application/json",
    "user-token": getAuthToken(),
  };
  return headerObj;
};

export const getTopics = async () => {
  try {
    const response = await fetch(topics_api, {
      method: "GET",
      headers: getRequestHeader(),
    });
    return validateResponse(response);
  } catch (error) {
    console.log("Error ", error);
  }
};

export const getGroups = async () => {
  try {
    const response = await fetch(groups_api, {
      method: "GET",
      headers: getRequestHeader(),
    });
    return validateResponse(response);
  } catch (error) {
    console.log("Error ", error);
  }
};

export const getHistory = async () => {
  try {
    const response = await fetch(`${answers_api}${PAGINATION}`, {
      method: "GET",
      headers: getRequestHeader(),
    });
    return validateResponse(response);
  } catch (error) {
    console.log("Error ", error);
  }
};

export const getFeedbackById = async (id) => {
  try {
    const response = await fetch(get_answer_api(id), {
      method: "GET",
      headers: getRequestHeader(),
    });
    return validateResponse(response);
  } catch (error) {
    console.log("Error ", error);
  }
};

export const submitFeedback = async (reqBody) => {
  try {
    const response = await fetch(answers_api, {
      method: "POST",
      headers: getRequestHeader(),
      body: JSON.stringify(reqBody),
      // body: reqBody,
    });
    return validateResponse(response);
  } catch (error) {
    console.log(
      "Error while submitting feedback. Error in (submitFeedback) : ",
      error
    );
  }
};

export const updateFeedback = async (reqBody, id) => {
  try {
    const response = await fetch(update_answers_api(id), {
      method: "PUT",
      headers: getRequestHeader(),
      body: JSON.stringify(reqBody),
    });
    return validateResponse(response);
  } catch (error) {
    console.log(
      "Error while submitting feedback. Error in (submitFeedback) : ",
      error
    );
  }
};

export const getCategories = async () => {
  try {
    const response = await fetch(`${category_api}`, {
      method: "GET",
      headers: getRequestHeader(),
    });
    return validateResponse(response);
  } catch (error) {
    console.log("Error ", error);
  }
};

// User Registrations
export const getUsers = async () => {
  try {
    const condiation = `&`
    const response = await fetch(`${users_api}${PAGINATION}`, {
      method: "GET",
      headers: getRequestHeader(),
    });
    return validateResponse(response);
  } catch (error) {
    console.log("Error ", error);
  }
};

export const updateUser = async (reqBody) => {
  try {
    const response = await fetch(updateUser_api, {
      method: "POST",
      headers: getRequestHeader(),
      body: JSON.stringify(reqBody),
    });
    return validateResponse(response);
  } catch (error) {
    console.log(
      "Error while submitting feedback. Error in (submitFeedback) : ",
      error
    );
  }
};