import BubbleChart from "../../components/Charts/BubbleChart";
import ScatterChartComp from "../../components/Charts/ScatterChart";

const getConvertedDataForScatterGraph = (data) => {
  if (!data) return [];

  const feedback = data.feedback;
  let categoryAxisPoints = [];
  for (const group in feedback) {
    const catData = feedback[group];
    for (const category in catData) {
      let categoryRating = catData[category];
      categoryRating.title = category;
      categoryAxisPoints.push(categoryRating);
    }
    // console.log("categoryAxisPoints ", categoryAxisPoints);
  }
  return { Categoriess: categoryAxisPoints };
};

const scatterPayloadGroupwise = (data) => {
  if (!data) return {};

  const feedback = data.feedback;
  let scatterPayload = {};
  for (const group in feedback) {
    let categoryAxisPoints = [];
    const catData = feedback[group];
    for (const category in catData) {
      let categoryRating = catData[category];
      categoryRating.title = category;
      categoryRating.group = group;

      categoryAxisPoints.push(categoryRating);
    }
    scatterPayload[group] = categoryAxisPoints;
  }
  // console.log("scatterPayload: ", scatterPayload);
  return scatterPayload;
};

const Analytics = (props) => {
  const { data } = props;

  // This will show all categories without group seperation
  // const scatterAxisData = getConvertedDataForScatterGraph(data);

  // This will show all categories based on groups
  const scatterAxisData = scatterPayloadGroupwise(data);

  return (
    <div className="pr-5">
      {/* <ScatterChartComp data={scatterAxisData} /> */}
      <BubbleChart metaData1={scatterAxisData} metaData={metaData} tooltipData={tooltipData} />
    </div>
  );
};
export default Analytics;



const metaData = [
  {
    "title": "Title 1",
    "y": 3,
    "x": 2,
    "radius": 100,
  },
  {
    "title": "Title 1",
    "y": 3,
    "x": 3,
    "radius": 100,
  },
  {
    "title": "Title 1",
    "y": 3,
    "x": 4,
    "radius": 100,
  },
  {
    "title": "Title 1",
    "y": 2,
    "x": 2,
    "radius": 100,
  },
  {
    "title": "Title 1",
    "y": 2,
    "x": 1,
    "radius": 100,
  },
  {
    "title": "Title 1",
    "y": 5,
    "x": 3,
    "radius": 100,
  },
]

const tooltipData = [
  {
    "title": "Title 1",
    "y": 3,
    "x": 2,
    "radius": 100,
  }
]
