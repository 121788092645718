import { useNavigate } from "react-router-dom";
import { BTN_MAPPING_TITLE, BTN_NEWFEEDBACK_TITLE } from "../../constants";
let logoImage = require("../../images/home3.png");
let bgImage = require("../../images/bgImage.jpg");
let homeVideo = require("../../images/homeV1.mp4");

const BTN_MAPPING_DESC = "I would like to view my earlier responses";

const BTN_NEWFEEDBACK_DESC = "I would like to start stakeholder mapping";

const descriptionText = (text) => {
  return (
    <div className="text-lg font-medium">
      <h1>{text}</h1>
    </div>
  );
};

const mappingButton = (title, onClick) => {
  return (
    <>
      <button
        className="flex items-center justify-center text-lg h-16 w-auto bg-transparent hover:bg-blue-700 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded-md"
        onClick={onClick}
      >
        {title}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
          style={{ marginLeft: "20px" }}
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M8.25 4.5l7.5 7.5-7.5 7.5"
          />
        </svg>
      </button>
    </>
  );
};

function Dashboard() {
  const navigate = useNavigate();

  const btn_class = "flex flex-col p-8 space-y-8";

  const mainBlock = () => {
    return (
      <div className="">
        <h2 className="text-center text-3xl text-indigo-900 font-semibold xl:text-4xl">
          Stakeholder Mapping tool
        </h2>
        <h3 className="mt-4 mb-6 text-md font-semibold text-indigo-400 xl:text-xl">
          Specify, Identify & Rate, Visualize, Act
        </h3>
        <div className={btn_class}>
          {descriptionText(BTN_NEWFEEDBACK_DESC)}
          {mappingButton(BTN_NEWFEEDBACK_TITLE, onNewFeedbackClick)}
        </div>
        <div className={btn_class}>
          {descriptionText(BTN_MAPPING_DESC)}
          {mappingButton(BTN_MAPPING_TITLE, onMappingClick)}
        </div>
      </div>
    );
  };

  const onMappingClick = () => {
    navigate("/feedbacks");
  };
  const onNewFeedbackClick = () => {
    navigate("/wizard");
  };

  return (
    <div className="lg:flex w-full">
      <div className="hidden lg:flex bg-indigo-100 flex-1 h-screen">
        <img
          src={bgImage}
          className="w-full h-full"
          alt="introduction page image"
        />
      </div>
      <div className="lg:w-6/12 xl:h-screen flex items-center justify-center">
        <div className="mt-8 px-12 sm:px-24 md:px-48 lg:px-12 xl:px-20 xl:max-w-2xl">
          {mainBlock()}
        </div>
      </div>
    </div>
  );
}
export default Dashboard;
