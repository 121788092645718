import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LoadingSkeleton } from "../../components/common/LoadingSkeleton";
import Modal from "../../components/common/Modal";
import { TableSkeleton } from "../../components/common/TableSkeleton";
import Table from "../../components/History/Table";
import { sortArrByDate, sortArrByString } from "../../components/utility";
import Wizard from "../../components/Wizard";
import { BTN_NEWFEEDBACK_TITLE } from "../../constants";
import { getHistory } from "../../service";
import Analytics from "../Feedback/Analytics";
import { getUser, clearUser } from "../../service/Auth";

const HISTORY_COLUMNS = [
  {
    title: "date",
    sortType: "date",
  },
  {
    title: "topic",
    sortType: "string",
  },
  {
    title: "groups",
    sortType: false,
  },
  /*  {
    title: "categories",
    sortType: false,
  }, */
  {
    title: "status",
    sortType: "string",
  },
  {
    title: "Actions",
    sortType: false,
  },
];

const History = () => {
  const [history, setHistory] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  const [actionType, setActionName] = useState("");
  const [selectedRow, setSelectedRow] = useState({});
  const [showModal, setPopupOpen] = useState(false);
  const [isGraphModal, setGraphModal] = useState(false);
  const [value, setValue] = useState("");
  const [searchText, setSearchText] = useState("");
  const [tableFilter, setTableFilter] = useState([]);
  const [sortOrder, setSortOrder] = useState("desc");
  const [sortColumn, setSortColumn] = useState("");
  const [isLoading, setLoading] = useState(false);

  const user = getUser();

  const navigateTo = (path) => navigate(path);

  useEffect(() => {
    fetchHistoryData();
    showMapForSubmittedForms();
  }, []);

  // Show map/graph for submitted forms directly on /feedback page onload.
  const showMapForSubmittedForms = () => {
    const formData = location.state;
    if (formData) {
      setSelectedRow(formData);
      setGraphModal(true);
      // Clear prev loaded location state
      navigate(location.pathname, {});
    }
  };
  const handleSetSortOrder = (col) => {
    if (col.sortType) {
      const toggleOrder = sortOrder === "desc" ? "asc" : "desc";
      setSortOrder(toggleOrder);
      setSortColumn(col);
    }
  };

  // extract categories from feedback object and add it as an string array to each record
  const getHistoryTableFormattedData = (data) => {
    let columnBasedTableData = [];
    data.map((row) => {
      if (row.feedback) {
        for (const [key, value] of Object.entries(row.feedback)) {
          row.categories = Object.keys(value);
        }
      }

      return row;
    });
  };

  const fetchHistoryData = async () => {
    try {
      // Fetch history page data / api logic
      setLoading(true);
      getHistory().then((data) => {
        // getHistoryTableFormattedData(data);
        if (!data.errorData) {
          const sortedData = sortArrByDate(data, "created");
          setHistory(sortedData);
        }
        setLoading(false);
      });
    } catch (err) {
      setLoading(false);
      console.log("Error ", err);
    }
  };

  useEffect(() => {
    if (user === "null") {
      clearUser();
      navigateTo("/login");
      return;
    }
  }, [user]);

  const getFilteredData = () => {
    /* if (!searchText || searchText.length < 3) {
      return history;
    } */
    let filterData = history;
    if (searchText) {
      filterData = history.filter((obj) =>
        Object.keys(obj).some((key) => {
          if (key === "groups") {
            const filterGroup = Object.keys(obj[key]);
            return filterGroup.some((key) => {
              return key.toLowerCase().includes(searchText.toLowerCase());
            });
          }
          return String(obj[key])
            .toLowerCase()
            .includes(searchText.toLowerCase());
        })
      );
    }

    if (sortColumn && sortColumn.sortType === "string") {
      const sortByString = sortArrByString(
        filterData,
        sortColumn.title,
        sortOrder
      );
      return sortByString;
    } else if (sortColumn && sortColumn.sortType === "date") {
      const sortedData = sortArrByDate(filterData, "created", sortOrder);
      return sortedData;
    }
    return filterData;
  };

  //search filter
  const onSearchChange = (e) => {
    if (e.target.value !== "") {
      setSearchText(e.target.value);
      /* setValue(e.target.value);
      const filterTable = history.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      setTableFilter([...filterTable]); */
    } else {
      setSearchText("");
    }
  };

  const handleGraphClick = (data) => {
    setSelectedRow(data);
    setGraphModal(true);
  };

  const closeGraphModal = () => {
    setSelectedRow(null);
    setGraphModal(false);
  };

  // Click Handlers
  const onRowClick = (data, actionType) => {
    console.log("setActionName ", data);
    console.log("actionType ", actionType);
    setActionName(actionType);
    setSelectedRow(data);
    setPopupOpen(true);
  };

  const openModal = () => setPopupOpen(true);
  const closeModal = () => setPopupOpen(false);
  // Click Handlers

  const newFeedbackBtn = () => {
    return (
      <div className="sticky top-0 right-0 mb-8 z-50">
        <button
          type="button"
          className="text-white font-bold text-sm px-5 py-2.5 mr-2 mb-2 bg-blue-700 hover:bg-blue-800 focus:outline-none"
          onClick={() => navigate("/wizard")}
        >
          {BTN_NEWFEEDBACK_TITLE}
        </button>
      </div>
    );
  };

  const historyTable = () => {
    return (
      <>
        {newFeedbackBtn()}
        {!showModal && (
          <Table
            columns={HISTORY_COLUMNS}
            data={getFilteredData()}
            showActionBtn={true}
            actionType={["view", "edit"]}
            onRowClick={onRowClick}
            handleGraphClick={handleGraphClick}
            searchText={searchText}
            onSearchChange={onSearchChange}
            sortColumn={sortColumn}
            sortOrder={sortOrder}
            handleSetSortOrder={handleSetSortOrder}
          />
        )}
        {isGraphModal && (
          <Modal
            isOpen={isGraphModal}
            modalTitle={"Your stakeholders map"}
            title={`Map for - ${selectedRow.topic}`}
            subTitle={"[Topic]"}
            onClose={closeGraphModal}
            onConfirm={closeGraphModal}
            hideActionButtons={true}
            childComponent={<Analytics data={selectedRow} />}
          />
        )}
        {showModal && (
          <div>
            <button class="btn btn-outline" onClick={closeModal}>
              Back to History
            </button>
            <Wizard selectedFormData={selectedRow} />
            {/* <Modal
          isOpen={showModal}
          onClose={closeModal}
          onConfirm={closeModal}
          title={`${actionType.toString().toUpperCase()} Feedback`}
          childComponent={
            <Wizard selectedFormData={selectedRow}/>
          }
        /> */}
          </div>
        )}
      </>
    );
  };

  return (
    <div
      className={`w-full py-10 px-4 sm:px-6 lg:px-8 ${
        isGraphModal ? "fixed" : "relavtive"
      }`}
    >
      {isLoading ? <TableSkeleton /> : historyTable()}
    </div>
  );
};
export default History;
