const STICK_VALUES = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const Slider = ({ handleChange, selectedValue = 0, isDisable }) => {
  // console.log("selectedValue ", selectedValue);
  const avtiveClass = "text-cyan-500 font-bold";

  return (
    <div className="w-96">
      <input
        type="range"
        min="0"
        max={STICK_VALUES.length - 1}
        value={selectedValue}
        className={`range range-xs range-accent ${
          isDisable && "cursor-not-allowed"
        }`}
        step=""
        onChange={handleChange}
        disabled={isDisable}
      />
      <div class="w-full flex justify-between text-xs pl-1">
        {STICK_VALUES.map((val) => {
          return (
            <span
              className={`${
                parseInt(selectedValue, 10) === val && avtiveClass
              } avtiveClass`}
            >
              {val}
            </span>
          );
        })}
      </div>
    </div>
  );
};

export default Slider;
