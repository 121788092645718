import { useState, createRef } from "react";
import { forgotPasswordFields } from "../../constants/formFields";
import { forgotPassword_api } from "../../constants/api";
import FormAction from "./FormAction";
import Input from "./Input";
import Spinner from "../common/Spinner";

let bgImage = require("../../images/bgImage.jpg");

const fields = forgotPasswordFields;
let fieldsState = {};
fields.forEach((field) => (fieldsState[field.id] = ""));

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [errorMsg, setError] = useState(null);
  const [successMsg, setSuccess] = useState(null);
  const [isloading, setLoading] = useState(false);
  let textInput = createRef();

  const handleChange = (e) => {
    setEmail(e.target.value);
    console.log(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    fetch(forgotPassword_api + email).then((response) => {
      if (response.status !== 200) {
        setError(`Oops, Something went wrong, Unable to reset password. Please re-check entered email and try again.`);
        setLoading(false);
      } else {
        setSuccess(
          "A reset password link has been sent to the specified email address. Please check your email to reset your password"
        );
        setLoading(false);
      }
    });
  };

  const showErrorMsg = () => {
    if (!errorMsg) return;

    return (
      <div
        className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
        role="alert"
      >
        <div className="w-11/12">
          <strong className="font-bold">Error: </strong>
          <span className="block sm:inline">{errorMsg}</span>
        </div>
        <span
          className="absolute top-0 bottom-0 right-0 px-4 py-3"
          onClick={() => setError(null)}
        >
          <svg
            className="fill-current h-6 w-6 text-red-500"
            role="button"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <title>Close</title>
            <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
          </svg>
        </span>
      </div>
    );
  };

  const showSuccessMsg = () => {
    if (!successMsg) return;

    return (
      <div
        className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative"
        role="alert"
      >
        <div className="w-11/12 text-left">
          <span className="block sm:inline">{successMsg}</span>
        </div>
        <div>
          <span
            className="absolute top-0 bottom-0 right-0 px-4 py-3"
            onClick={() => setSuccess(null)}
          >
            <svg
              className="fill-current h-6 w-6 text-green-500"
              role="button"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <title>Close</title>
              <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
            </svg>
          </span>
        </div>
      </div>
    );
  };

  return (
    <form className="mt-2 space-y-6" onSubmit={handleSubmit}>
      <div className="" tabIndex={0}>
        {isloading && (
          <div className="flex h-screen absolute -top-10 left-1/2 z-50">
            <Spinner />
          </div>
        )}
        {fields.map((field) => (
          <Input
            key={field.id}
            handleChange={handleChange}
            labelText={field.labelText}
            labelFor={field.labelFor}
            id={field.id}
            refs={textInput}
            name={field.name}
            type={field.type}
            isRequired={field.isRequired}
            placeholder={field.placeholder}
          />
        ))}
      </div>
      {showErrorMsg()}
      {showSuccessMsg()}
      <FormAction handleSubmit={handleSubmit} text="Send Email" />
    </form>
  );
};

export default ForgotPassword;
