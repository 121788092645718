import { useState, useEffect } from "react";
import moment from "moment";
import {
    DISABLED_STATUS,
    EDIT_ACTION,
    ENABLED_STATUS,
    SUBMIT_STATUS,
    UPDATE_STATUS,
    VIEW_ACTION,
} from "../../constants";
import { useNavigate } from "react-router-dom";
import UpArrow from "../common/UpArrow";
import DownArrow from "../common/DownArrow";
import ToggleButton from "../common/ToggleButton";

// show maximum no of array element/string in cell.
const NO_OF_ARRAY_ELE_SHOW = 3;

const UsersTable = (props) => {
    const {
        columns,
        data,
        actionType,
        onSearchChange,
        searchText,
        tableFilter,
        sortColumn,
        sortOrder,
        handleSetSortOrder,
        handleOnAction,
    } = props;

    let navigate = useNavigate();

    const getFormEditActionIcon = (row) => {
        const userStatus = row.userStatus && row.userStatus === ENABLED_STATUS ? true : false;
        return (
            <div className="flex justify-center">
                <ToggleButton data={row} isEnable={userStatus} onChange={handleOnAction} />
            </div>

        )
    };

    const showTableFilterBlock = () => {
        return (
            <div className="flex flex-col justify-end items-end my-4">
                <label htmlFor="search">
                    <input
                        type="text"
                        id="search"
                        className="bg-white-50 border text-sm rounded-lg block w-80 pl-10 p-2.5 border-gray-200 placeholder-gray-400"
                        placeholder="Quick Search..."
                        value={searchText}
                        onChange={onSearchChange}
                    />
                </label>
                <p className="text-sm text-slate-400 p-1">
                    *Please use at least 3 characters
                </p>
            </div>
        );
    };

    const showStatusCell = (row) => {
        const status = row.userStatus;
        if (!status) return noDataText();

        const statyStyle = status === DISABLED_STATUS ? "bg-red-200" : "bg-green-200";

        return <span className={`inline-block w-20 p-2 rounded-md ${statyStyle}`}>{status}</span>;
    };

    const noDataText = () => {
        return <span className="text-slate-400">---</span>;
    };

    return (
        <div class="p-5 bg-gray-50">
            <h1 class="text-xl font-medium mb-2">All Users</h1>
            {showTableFilterBlock()}
            <div class="overflow-x-auto rounded-lg shadow">
                <table className="w-full">
                    <thead class="bg-gray-100 border-b-2 border-gray-200 ">
                        <tr>
                            {columns.map((col) => {
                                return (
                                    <th
                                        class="p-3 text-sm font-semibold tracking-wide text-centre"
                                        onClick={() => handleSetSortOrder(col)}
                                    >
                                        <div
                                            className={`flex flex-row justify-center items-center text-sm font-medium p-1 ${col.sortType && "cursor-pointer hover:text-blue-700"
                                                } ${col.sortType &&
                                                col.title === sortColumn.title &&
                                                "text-blue-700"
                                                }`}
                                        >
                                            {col.display.toUpperCase()}
                                            {col.sortType &&
                                                (sortOrder === "desc" ? <DownArrow /> : <UpArrow />)}
                                        </div>
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody class="divide-y divide-gray-100">
                        {data.map((row) => {
                            return (
                                <tr class="bg-white odd:bg-white even:bg-slate-50 transition ease-in-out hover:-translate-y-1 hover:scale-5 hover:bg-indigo-100">
                                    <td class="p-3 text-sm text-gray-700">
                                        {moment(new Date(row.created)).format(
                                            "DD/MM/YYYY HH:mm a"
                                        ) || noDataText()}
                                    </td>
                                    <td class="p-3 text-sm text-gray-700">
                                        {`${row.firstName} ${row.lastName}` || noDataText()}
                                    </td>
                                    <td class="p-3 text-sm text-gray-700">
                                        {row.email || noDataText()}
                                    </td>
                                    <td class="p-3 text-sm text-gray-700">
                                        {showStatusCell(row)}
                                    </td>
                                    <td class="text-sm text-gray-700">
                                        {getFormEditActionIcon(row)}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
};
export default UsersTable;
