import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  LBL_NOT_STARTED,
  LBL_PARTIAL,
  LBL_SUBMITTED,
  SUBMIT_STATUS,
  UPDATE_STATUS,
} from "../../constants";
import { getTopics, submitFeedback, updateFeedback } from "../../service";
import BackdropLoader from "../common/BackdropLoader";
import Checkbox from "../common/Checkbox";
import Modal from "../common/Modal";
import Category from "../Feedback/Category";
import FeedbackQuestion from "../Feedback/FeedbackQuestion";
import Group from "../Feedback/Group";
import Topic from "../Feedback/Topic";
import { getUser, clearUser } from "../../service/Auth";
import { FEEDBACK_ROUTE } from "../../constants/Routes";

const TOPIC_LABEL = "topic";
const GROUP_LABEL = "groups";
const CATEGORIES_LABEL = "userCategories";
const CATEGORY_LABEL = "activeCategory";
const FEEDBACK_ANSWERS = "feedback";
const FEEDBACK_STATUS = "feedbackStatus";

const ERROR_MESSAGE =
  "Error while uploading your feedback. Please contact Admin";
const SUBMIT_SUCCESS_MESSAGE = "Your feedback submitted successfully !";
const SAVE_CONTINUE_SUCCESS_MESSAGE =
  "Your feedback has been saved successfully !";
const UPLOAD_MESSAGE = "Uploading your feedback. Please wait...";

const feedbackQuestionOption = {
  id: "feedbackOptions",
  options: ["European Commisioner", "Member of Parliament", "Sport Minister"],
  queSections: [
    {
      id: "power",
      title:
        "Does the X (name of the stackholder sub-group) have power in your project?",
      answers: ["yes", "no"],
    },
    {
      id: "interest",
      title:
        "Is the X (name of the stackholder sub-group) interested in your project?",
      answers: ["yes", "no"],
    },
    {
      id: "alignment",
      title: "Is the X (name of the stackholder sub-group) aligned with you?",
      answers: ["yes", "no", "alignment unknown"],
    },
    {
      id: "influenceability",
      title: "Is the X (name of the stackholder sub-group) influencable?",
      answers: ["yes", "no", "influenceability unknown"],
    },
  ],
};

const styles = {
  divider_line: "border-b border-50",
};

const FeedbackForm = (props) => {
  const { data = [], defaultFormData = {}, groupsList } = props;

  const user = getUser();
  const navigate = useNavigate();

  const navigateTo = (path) => navigate(path);
  /* 
        // Set selected form data in state
        formData = {
            topic: '', // single selected topic name
            group: {'group-name1': true, 'group-name2': true, ...},
            categories: ['cat1', 'cat2', ...]
        }
     */
  const [formData, setFormData] = useState(defaultFormData || {});
  const [showModal, setShowModal] = useState(false);

  const [isLoading, setLoader] = useState(false);
  const [showAlert, setAlert] = useState(false);
  const [alertMesssage, setAlertMesssage] = useState("");
  const [severity, setSeverity] = useState(null);
  const prevFormData = useRef(JSON.parse(JSON.stringify(formData)));
  const [currentGroupName, setCurrentGroupName] = useState("");
  //const [prevData, setPrevData] = useState(prevFormData);

  /* .......................................... */

  const queSectionsData = feedbackQuestionOption.queSections || [];
  const answerOptionsData = feedbackQuestionOption.options || [];

  // This function return status for each
  // return : {groupName: 'not yet started/partial/submitted', ...}
  const getCategoryFeedbackStatus = () => {
    const activeGroups = formData[GROUP_LABEL];
    const userCategory = formData[CATEGORIES_LABEL];
    const feedbackData = formData[FEEDBACK_ANSWERS];

    const questionLen = feedbackQuestionOption.queSections.length;

    let feedbackStatusGroupWise = {};

    if (feedbackData && activeGroups) {
      // check are all ('power',etc ) answered or not
      for (let groupName in activeGroups) {
        const isActiveGroup = activeGroups[groupName];
        if (isActiveGroup) {
          const groupHasFeedback = feedbackData.hasOwnProperty(groupName);

          // console.log(`${groupName} : groupHasFeedback:: ${groupHasFeedback}`);
          if (groupHasFeedback) {
            // if single category has answered make status 'partial'
            feedbackStatusGroupWise[groupName] = LBL_PARTIAL;

            const allCategoryArr = userCategory[groupName];

            for (var i = 0; i < allCategoryArr.length; i++) {
              const catName = allCategoryArr[i];
              const categoryFeedback = feedbackData[groupName];
              const cateAnswers = categoryFeedback[catName];

              // If category is not answered or partial answered break
              if (!cateAnswers || Object.keys(cateAnswers).length < 4) {
                break;
              }
              // If all category has 4 answers
              if (
                i == allCategoryArr.length - 1 &&
                Object.keys(cateAnswers).length === questionLen
              ) {
                feedbackStatusGroupWise[groupName] = LBL_SUBMITTED;
              }
            }
          }
        }
      }
    } else if (activeGroups) {
      for (let key in activeGroups) {
        console.log(key, activeGroups[key]);
        if (activeGroups[key]) {
          feedbackStatusGroupWise[key] = LBL_NOT_STARTED;
        }
      }
    }

    console.log("feedbackStatusGroupWise ::::: ", feedbackStatusGroupWise);
    return feedbackStatusGroupWise;
  };

  const openModal = () => setShowModal(true);

  const closeModal = () => {
    //have to check if form data is differnt from default form data
    // else default form data
    if (
      prevFormData.current &&
      (prevFormData.current.feedback === undefined ||
        prevFormData.current.feedback === null)
    ) {
      const newFormData = JSON.parse(JSON.stringify(formData));
      newFormData.feedback = {};
      setFormData(newFormData);
    } else if (
      prevFormData.current.status === "Pending" &&
      Object.keys(prevFormData.current.feedback).length
    ) {
      const newFormData = JSON.parse(JSON.stringify(formData));
      newFormData.feedback[currentGroupName] =
        prevFormData.current.feedback[currentGroupName];
      //console.log("newFormData", newFormData);

      setFormData(newFormData);
    } else {
      // console.log("prevdata", prevFormData.current);
      const newFormData = JSON.parse(JSON.stringify(prevFormData.current));

      setFormData(newFormData);
    }

    setShowModal(false);
  };

  const onModalConfirm = () => {
    //need to setsate for form data with current form data.
    prevFormData.current = JSON.parse(JSON.stringify(formData));
    closeModal();

    const feedbackStatus = getCategoryFeedbackStatus();
    handleSelection(FEEDBACK_STATUS, feedbackStatus);
    // console.log("feedbackStatus:::    ", feedbackStatus);
  };

  const handleOnError = () => {
    setLoader(false);
    setAlertMesssage(ERROR_MESSAGE);
    setSeverity("error");
  };

  const handleOnSuccess = (msg = "Done!") => {
    setLoader(false);
    setAlertMesssage(msg);
    setSeverity("success");
  };

  const onLoaderModalClose = () => {
    setAlert(false);
    setLoader(false);
    setAlertMesssage(null);
    setSeverity(null);

    // Redirect only if feedback submitted successfully.
    if (severity === "success") {
      // navigate(-1);
      // Pass current submitted form data to feedback page to automatically show map on submit
      console.log("formData::: ", formData);
      if (formData && formData.status === SUBMIT_STATUS) {
        navigate(`/${FEEDBACK_ROUTE}`, { state: formData });
      } else {
        navigate(`/${FEEDBACK_ROUTE}`);
      }
    }
  };

  const setStateBeforeUpload = () => {
    setAlert(true);
    setAlertMesssage(UPLOAD_MESSAGE);
    setLoader(true);
  };

  /* ----  ----- */
  const getGroupDataForSelectedTopic = () => {
    const groupData = data.filter((f) =>
      formData ? f.title === formData[TOPIC_LABEL] : []
    );

    return groupData[0];
  };
  /* ----  ----- */

  const handleFormSubmit = (btnClick) => {
    const objectId = formData["objectId"];
    const formStatus = formData["status"];

    // const feedbackStatus = getCategoryFeedbackStatus();

    const reqBody = {
      [TOPIC_LABEL]: formData[TOPIC_LABEL],
      [GROUP_LABEL]: formData[GROUP_LABEL],
      [CATEGORIES_LABEL]: formData[CATEGORIES_LABEL],
      [FEEDBACK_ANSWERS]: formData[FEEDBACK_ANSWERS],
      [FEEDBACK_STATUS]: formData[FEEDBACK_STATUS],
    };

    try {
      // Set upload flag in state on submit like [isloading,alertMsg etc]
      setStateBeforeUpload();

      if (btnClick === "submit") {
        // Form with 'submitted' status
        reqBody.status = SUBMIT_STATUS;
        if (objectId) {
          // It's an old feedback. Just update It
          onUpdate(reqBody, objectId, SUBMIT_SUCCESS_MESSAGE);
        } else {
          // It's a new feedback. Create a new one
          onSubmit(reqBody);
        }
      } else if (btnClick === "save") {
        // Form with 'pending' status
        reqBody.status = UPDATE_STATUS;
        if (objectId) {
          // It's an old feedback. Just update It
          onUpdate(reqBody, objectId);
        } else {
          // It's a new feedback. Create a new one
          onSubmit(reqBody, SAVE_CONTINUE_SUCCESS_MESSAGE);
        }
      }
    } catch (error) {
      console.log("error while Uploading form:", error);
      handleOnError("Error while Uploading form");
    }
  };

  // Submit form request to backend
  const onSubmit = (reqBody, isSuccessMsg) => {
    // Update current form state to state
    setFormData({ ...formData, ...reqBody });

    submitFeedback(reqBody)
      .then((data) => {
        if (data.code && data.message) {
          console.log(`${ERROR_MESSAGE} :  ${data}`);
          handleOnError();
        } else {
          console.log(isSuccessMsg || SUBMIT_SUCCESS_MESSAGE);
          handleOnSuccess(isSuccessMsg || SUBMIT_SUCCESS_MESSAGE);
        }
      })
      .catch((error) => {
        console.log(`${ERROR_MESSAGE} :  ${error}`);
        handleOnError();
      });
  };

  // Update form request to backend
  const onUpdate = (reqBody, objectId, isSuccessMsg) => {
    // Update current form state to state
    setFormData({ ...formData, ...reqBody });

    updateFeedback(reqBody, objectId)
      .then((data) => {
        if (data.code && data.message) {
          console.log(`${ERROR_MESSAGE} :  ${data}`);
          handleOnError();
        } else {
          console.log(isSuccessMsg || SAVE_CONTINUE_SUCCESS_MESSAGE);
          handleOnSuccess(isSuccessMsg || SAVE_CONTINUE_SUCCESS_MESSAGE);
        }
      })
      .catch((error) => {
        console.log(`${ERROR_MESSAGE} :  ${error}`);
        handleOnError();
      });
  };

  // On new customCategory add it to form data

  useEffect(() => {
    if (user === "null") {
      clearUser();
      navigateTo("/login");
      return;
    }
  }, [user]);

  const handleNewCategory = (value) => {
    setFormData({ ...formData, [CATEGORIES_LABEL]: value });
  };

  const getCategoriesForGroup = (groupName) => {
    const groupsData = groupsList;
    let newArr = [];

    groupsList &&
      groupsList.map((group) => {
        if (groupName === group.title) {
          group.categories &&
            group.categories.map((category) => {
              newArr = [...newArr, category.title];
            });
        }
      });

    return newArr;
  };

  // TODO: Remove this code(and related) once everything works as per requirement
  const getTopicRelatedCategoriesForGroup = (groupName) => {
    const groupsData = getGroupDataForSelectedTopic();
    let newArr = [];

    groupsData.groups &&
      groupsData.groups.map((group) => {
        if (groupName === group.title) {
          group.categories &&
            group.categories.map((category) => {
              newArr = [...newArr, category.title];
            });
        }
      });
    return newArr;
  };

  // on Group check/uncheck add or remove related default categories for new form only.
  const handleGroupSelection = async (name, groups) => {
    let defaultCategories = formData[CATEGORIES_LABEL];

    // TODO : Remove else part once everything works fine as per showAllGroups alltime change
    if (true || !formData.status) {
      if (groups[name]) {
        // set default assigned categories from checked group
        // const cateArr = getTopicRelatedCategoriesForGroup(name);
        const cateArr = getCategoriesForGroup(name);
        //console.log("defaultCategories", defaultCategories);

        defaultCategories = { ...formData[CATEGORIES_LABEL] };
      } else {
        // remove default assigned categories from checked group
        // delete formData[CATEGORIES_LABEL][name];
        delete defaultCategories[name];
        //delete formData[CATEGORIES_LABEL][name];

        //console.log("defaultCategories", defaultCategories);
      }
      //console.log("defaultCategories", defaultCategories);

      setFormData({
        ...formData,
        [GROUP_LABEL]: groups,
        [CATEGORIES_LABEL]: defaultCategories,
      });
    } else {
      // Handle default group selection with previous form state
      handleSelection(GROUP_LABEL, groups);
    }
  };

  //console.log("formData", formData);

  const handleSelection = (name, value) => {
    if (name === CATEGORY_LABEL) {
      openModal();
    }
    // console.log("handleSelection ", name);
    // console.log("handleSelection ", value);
    setFormData({ ...formData, [name]: value });
  };

  const onCheckBoxChange = (val) => {
    console.log("onCheckBoxChange : ", val);
  };

  const getModalTitle = () => {
    const activeGroup = formData[CATEGORY_LABEL];
    const title =
      (activeGroup &&
        `${formData[TOPIC_LABEL]} - ${activeGroup.group.title}`) ||
      "";
    return title;
  };

  const isStatusSubmitted = () => {
    return formData["status"] === SUBMIT_STATUS;
  };

  const isStatusPending = () => {
    return formData["status"] === UPDATE_STATUS;
  };

  const isTopicSelected = () => {
    if (Object.keys(formData).length === 0 || !formData[TOPIC_LABEL]) {
      return false;
    }
    return true;
  };

  /*   const someTruthy = (objData) =>
    objData && Object.values(objData).some((val) => val === true);

  const isGroupSelected = () => {
    console.log(
      "someTruthy(formData[GROUP_LABEL]) ",
      someTruthy(formData[GROUP_LABEL])
    );
    if (
      Object.keys(formData).length === 0 ||
      (formData[GROUP_LABEL] && !someTruthy(formData[GROUP_LABEL]))
    ) {
      return false;
    }
    return true;
  }; */

  const getBlockTitle = (title) => {
    return (
      <div id="card-title" className="font-bold mb-2 text-left text-slate-500">
        <h2>{title}</h2>
      </div>
    );
  };

  const getTopicBlock = () => {
    return (
      <>
        {getBlockTitle(
          "Please choose the topic from the dropdown or type a new topic"
        )}
        <Topic
          id="topic"
          selectedOption={formData[TOPIC_LABEL]}
          data={data || []}
          isDisable={isStatusSubmitted() || isStatusPending()}
          handleSelection={(val) => handleSelection("topic", val)}
        />
      </>
    );
  };

  const getGroupBlock = () => {
    // Filter data to get selected object for topic
    const selectedTopicData = data.filter((f) =>
      formData ? f.title === formData[TOPIC_LABEL] : {}
    );

    return (
      <>
        {getBlockTitle("Please choose the relevant stakeholder groups")}
        <Group
          id="group"
          defaultSelected={formData[GROUP_LABEL] || {}}
          data={selectedTopicData[0]}
          isDisable={isStatusSubmitted()}
          // handleSelection={(val) => handleSelection(GROUP_LABEL, val)}
          handleGroupSelection={(name, groups) =>
            handleGroupSelection(name, groups)
          }
          groupsList={groupsList}
        />
      </>
    );
  };

  const getCategory = () => {
    // Filter data to get selected object for topic
    const selectedTopicData = data.filter((f) =>
      formData ? f.title === formData[TOPIC_LABEL] : {}
    );

    return (
      <>
        {getBlockTitle(
          "Please choose the categories for the specific stakeholder groups"
        )}
        <Category
          status={formData.status}
          feedbackStatus={formData[FEEDBACK_STATUS] || {}}
          isDisable={isStatusSubmitted()}
          userCategories={formData[CATEGORIES_LABEL] || {}}
          selectedCategory={formData[CATEGORY_LABEL] || {}}
          selectedGroups={formData[GROUP_LABEL] || {}}
          // data={selectedTopicData[0]}
          data={groupsList}
          handleSelection={(val) => handleSelection(CATEGORY_LABEL, val)}
          handleNewCategory={handleNewCategory}
          setCurrentGroupName={setCurrentGroupName}
        />
      </>
    );
  };

  const getFormActionButtons = () => {
    const isDisable =
      formData["status"] === SUBMIT_STATUS || !formData["topic"];

    const disableClass = "opacity-30 disabled:cursor-not-allowed";

    return (
      <div className="flex justify-evenly p-6 border-t border-50">
        <button
          className={`w-45 px-3 basis-[19.2857143%] rounded text-white font-semibold text-sm bg-blue-700 hover:bg-blue-800 ${isDisable && disableClass
            }`}
          onClick={() => handleFormSubmit("save")}
          disabled={isDisable}
        >
          Save and Continue later
        </button>
        <button
          className={`w-45 px-3 py-3 basis-[19.2857143%] rounded text-white font-semibold text-sm bg-blue-700 hover:bg-blue-800 ${isDisable && disableClass
            }`}
          onClick={() => handleFormSubmit("submit")}
          disabled={isDisable}
        >
          Submit
        </button>
      </div>
    );
  };

  return (
    <div className="mt-5 max-w-l flex justify-center rounded overflow-visible shadow-lg border">
      <div className="w-11/12 py-4 max-w-screen-lg">
        <div
          class={`flex flex-col items-start mt-8 pb-5 ${styles.divider_line}`}
        >
          {getTopicBlock()}
        </div>
        {isTopicSelected() && (
          <>
            <div
              className={`flex flex-col items-start mt-8 pb-5 ${styles.divider_line}`}
            >
              {getGroupBlock()}
            </div>
            <div class="flex-none flex-col items-start mt-8 pb-2">
              {getCategory()}
            </div>
          </>
        )}
        {showModal && (
          <Modal
            isOpen={showModal}
            modalTitle={"Provide your feedback"}
            title={getModalTitle()}
            subTitle={"[Topic - Group]"}
            onClose={closeModal}
            onConfirm={onModalConfirm}
            childComponent={
              <FeedbackQuestion
                activeCategory={formData[CATEGORY_LABEL]}
                selectedAnswers={formData[FEEDBACK_ANSWERS] || {}}
                handleSelection={(val) =>
                  handleSelection(FEEDBACK_ANSWERS, val)
                }
                isDisable={isStatusSubmitted()}
              />
            }
          />
        )}
        {showAlert && (
          <BackdropLoader
            isOpen={showAlert}
            isLoading={isLoading}
            message={alertMesssage}
            severity={severity}
            onClose={onLoaderModalClose}
          />
        )}
        {getFormActionButtons()}
      </div>
    </div>
  );
  return (
    <div className="w-full m-5">
      <Topic
        selectedOption={formData[TOPIC_LABEL]}
        data={data}
        handleSelection={handleSelection}
      />
      <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2 text-left">
        Answer the following questions for the selected project
      </label>
      {queSectionsData.map((question) => {
        return (
          <div>
            <div>
              <label className="block text-left font-bold text-blue-700">
                {question.id.toString().toUpperCase()}
              </label>
              <label className="block tracking-wide text-gray-700 text-s font-bold my-3 text-left">
                {question.title}
              </label>
            </div>
            <div>
              {/* Form checkboxes based on options available for answers */}
              {answerOptionsData.map((ansText) => {
                return (
                  <div className="text-left  flex flex-row text-gray-600 text-xs">
                    <label className="w-5/12 mr-5">{ansText}</label>
                    <div className="flex justify-start w-full">
                      {question.answers.map((opt) => {
                        return (
                          <Checkbox
                            options={[
                              { id: opt, title: opt.toString().toUpperCase() },
                            ]}
                            onChange={onCheckBoxChange}
                          />
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default FeedbackForm;
