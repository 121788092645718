import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LoadingSkeleton } from "../../components/common/LoadingSkeleton";
import Modal from "../../components/common/Modal";
import { TableSkeleton } from "../../components/common/TableSkeleton";
import { sortArrByDate, sortArrByString } from "../../components/utility";
import Wizard from "../../components/Wizard";
import { ADMIN_USER_ROLE, ALERT_TYPE_ERROR, ALERT_TYPE_SUCCESS, BTN_NEWFEEDBACK_TITLE, DISABLED_STATUS, ENABLED_STATUS, TEST_USER_ROLE } from "../../constants";
import { getUsers, updateUser } from "../../service";
import Analytics from "../Feedback/Analytics";
import { getUser, clearUser } from "../../service/Auth";
import UsersTable from "../../components/UsersRegistration/UsersTable";
import ToastMessage from "../../components/common/ToastMessage";
import Spinner from "../../components/common/Spinner";

const TABLE_COLUMNS = [
    {
        title: "date",
        display: "date",
        sortType: "date",
    },
    {
        title: "firstName",
        display: 'Name',
        sortType: "string",
    },
    {
        title: "email",
        display: "email",
        sortType: "string",
    },
    {
        title: "userStatus",
        display: 'User Status',
        sortType: "string",
    },
    {
        title: "Actions",
        display: "Actions",
        sortType: false,
    },
];

const UserRegistration = () => {
    const [tabledata, setTableData] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();

    const [actionType, setActionName] = useState("");
    const [selectedRow, setSelectedRow] = useState({});
    const [showModal, setPopupOpen] = useState(false);
    const [isGraphModal, setGraphModal] = useState(false);
    const [value, setValue] = useState("");
    const [searchText, setSearchText] = useState("");
    const [tableFilter, setTableFilter] = useState([]);
    const [sortOrder, setSortOrder] = useState("desc");
    const [sortColumn, setSortColumn] = useState("");
    const [isLoading, setLoading] = useState(false);
    const [isProcessing, setProcessing] = useState(false);

    const [alert, setAlert] = useState(null);

    const user = getUser();

    const navigateTo = (path) => navigate(path);

    useEffect(() => {
        if (user === "null") {
            clearUser();
            navigateTo("/login");
            return;
        }
    }, [user]);

    useEffect(() => {
        fetchTableData();
    }, []);


    // Alert or toast message handlers
    const handleAlert = (alertValue) => {
        setAlert(alertValue);
        setProcessing(false);

        // Close toast message after 3 sec
        setTimeout(() => {
            handleCloseToast();
        }, 3000)
    }

    const handleCloseToast = () => {
        setAlert(null);
    }

    const handleSetSortOrder = (col) => {
        if (col.sortType) {
            const toggleOrder = sortOrder === "desc" ? "asc" : "desc";
            setSortOrder(toggleOrder);
            setSortColumn(col);
        }
    };

    const fetchTableData = async (isLoaderEnable = true) => {
        try {
            // Fetch tabledata page data / api logic
            isLoaderEnable && setLoading(true);
            getUsers().then((data) => {
                if (!data.errorData) {
                    const loggedInUser = JSON.parse(user);

                    // Don't show current loggedInUser/admin/test users
                    const usersData = data.filter(user => user.role !== ADMIN_USER_ROLE && user.role !== TEST_USER_ROLE && user.objectId !== loggedInUser.objectId);

                    const sortedData = sortArrByDate(usersData, "created");
                    setTableData(sortedData);
                }
                isLoaderEnable && setLoading(false);
            });
        } catch (err) {
            isLoaderEnable && setLoading(false);
            console.log("Error ", err);
        }
    };

    const getFilteredData = () => {
        /* if (!searchText || searchText.length < 3) {
          return tabledata;
        } */
        let filterData = tabledata;
        if (searchText) {
            filterData = tabledata.filter((obj) =>
                Object.keys(obj).some((key) => {
                    if (key === "groups") {
                        const filterGroup = Object.keys(obj[key]);
                        return filterGroup.some((key) => {
                            return key.toLowerCase().includes(searchText.toLowerCase());
                        });
                    }
                    return String(obj[key])
                        .toLowerCase()
                        .includes(searchText.toLowerCase());
                })
            );
        }

        if (sortColumn && sortColumn.sortType === "string") {
            const sortByString = sortArrByString(
                filterData,
                sortColumn.title,
                sortOrder
            );
            return sortByString;
        } else if (sortColumn && sortColumn.sortType === "date") {
            const sortedData = sortArrByDate(filterData, "created", sortOrder);
            return sortedData;
        }
        return filterData;
    };

    //search filter
    const onSearchChange = (e) => {
        if (e.target.value !== "") {
            setSearchText(e.target.value);
        } else {
            setSearchText("");
        }
    };

    const handleOnAction = (data) => {
        setSelectedRow(data);
        try {
            setProcessing(true);

            // Fetch tabledata page data / api logic
            const userStatusToggle = data.userStatus === ENABLED_STATUS ? false : true;

            const reqBody = { "userObjectId": data.objectId, "enable": userStatusToggle }

            updateUser(reqBody).then((response) => {
                console.log('updateUser ', response);
                if (!response.errorData) {
                    fetchTableData(false);

                    // Show record upadated success message
                    handleAlert({
                        message: "Record Updated Successfully!",
                        type: ALERT_TYPE_SUCCESS,
                    });
                } else {
                    handleAlert({
                        message: "Error occured while updating User! Please contact to admin.",
                        type: ALERT_TYPE_ERROR
                    });
                }
            });
        } catch (err) {
            setLoading(false);
            handleAlert({
                message: "Error occured while updating User ! Please contact to admin.",
                type: ALERT_TYPE_ERROR
            });
            console.log("Error ", err);
        }
    };

    const renderTable = () => {
        return (
            <UsersTable
                columns={TABLE_COLUMNS}
                data={getFilteredData()}
                showActionBtn={true}
                actionType={["view", "edit"]}
                handleOnAction={handleOnAction}
                searchText={searchText}
                onSearchChange={onSearchChange}
                sortColumn={sortColumn}
                sortOrder={sortOrder}
                handleSetSortOrder={handleSetSortOrder}
            />
        );
    };

    return (
        <div className={`w-full py-10 px-4 sm:px-6 lg:px-8 relavtive`}>
            {isLoading ? <TableSkeleton /> : renderTable()}
            {isProcessing && (
                <div className="flex h-screen absolute -top-10 left-1/2 z-50">
                    <Spinner />
                </div>
            )}
            {
                alert &&
                <ToastMessage alert={alert} closeToast={handleCloseToast} />
            }
        </div>
    );
};
export default UserRegistration;