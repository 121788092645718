import React from "react";
import Checkbox from "../common/Checkbox";
import Slider from "../common/Slider";
import { mergeDeep } from "../utility";

const REPLACE_BY_GROUPNAME = "(sub-group)";

const feedbackQuestionOption = {
  id: "feedbackOptions",
  queSections: [
    {
      id: "power",
      title: `Please rate the power of the ${REPLACE_BY_GROUPNAME} in your project on a scale of 1 to 10.`,
      definition: `(Power the stakeholder has to influence decisions, change policy, validate a budget, shift strategies, etc. This can be reflected e.g. by their position within boards, organisations or institutions, but also their popularity, their personal charisma, their network, their expertise.)`,
      answers: ["rating"],
      feedbackType: "slider",
    },
    {
      id: "interest",
      title: `Please rate the interest of the ${REPLACE_BY_GROUPNAME} in your project on a scale of 1 to 10.`,
      definition: `(Interest the stakeholder has in your activities, their potential engagement on similar topics as you, or benefits they could gain from cooperating with you.)`,
      answers: ["rating"],
      feedbackType: "slider",
    },
    {
      id: "alignment",
      title: `Please rate the alignment of the ${REPLACE_BY_GROUPNAME} in your project on a scale of 1 to 10.`,
      definition: `(The stakeholder’s attitude (support versus opposition) towards your activities.)`,
      // answers: ["yes", "no", "alignment unknown"],
      // feedbackType: "checkbox",
      answers: ["rating"],
      feedbackType: "slider",
    },
    {
      id: "influenceability",
      title: `Please rate the influenceability of the ${REPLACE_BY_GROUPNAME} in your project on a scale of 1 to 10.`,
      definition: `(Your ability to influence the stakeholder and make them change their alignment/interest.)`,
      // answers: ["yes", "no", "influenceability unknown"],
      // feedbackType: "checkbox",
      answers: ["rating"],
      feedbackType: "slider",
    },
  ],
};

const FeedbackQuestion1 = () => {
  return <div>FeedbackQuestion</div>;
};

const FeedbackQuestion = (props) => {
  const {
    selectedAnswers = {},
    handleSelection,
    activeCategory,
    isDisable,
  } = props;

  const queSectionsData = feedbackQuestionOption.queSections || [];
  const answerOptionsData = activeCategory.category || [];
  const activeGroupName = activeCategory && activeCategory.group.title;

  // console.log("feedbackQuestionOption ", feedbackQuestionOption);
  // console.log("queSectionsData ", queSectionsData);

  // Handle slider value change
  const onSliderChange = (event, questionId, questText) => {
    const value = event.target.value;
    console.log("selected checkbox : ", `${[questText]}: ${[value]}`);

    const activeGroup = activeCategory && activeCategory.group.title;
    const activeCate = activeCategory && activeCategory.category.title;

    const submitAnserObject = {
      [questionId]: { [questText]: value },
    };

    const newObject = {
      [activeGroup]: { [questText]: { [questionId]: value } },
    };

    const mergeWithOldSelected = mergeDeep(selectedAnswers, newObject);
    handleSelection(mergeWithOldSelected);
  };

  // Handle checkbox value change
  const onCheckBoxChange = (event, questionId, questText) => {
    const isChecked = event.target.checked;
    const name = event.target.name;
    console.log("selected checkbox : ", `${[questText]}: ${[name]}`);

    const activeGroup = activeCategory && activeCategory.group.title;
    const activeCate = activeCategory && activeCategory.category.title;

    if (isChecked) {
      const submitAnserObject = {
        [questionId]: { [questText]: name },
      };

      const newObject = {
        [activeGroup]: {
          [questionId]: { [questText]: name },
          // [activeCate]: { [questionId]: { [questText]: name } },
        },
      };

      const mergeWithOldSelected = mergeDeep(selectedAnswers, newObject);
      handleSelection(mergeWithOldSelected);
    } else {
      // delete selectedAnswers[activeGroup][activeCate][questionId][questText];
      delete selectedAnswers[activeGroup][questionId][questText];
      handleSelection(selectedAnswers);
    }
  };

  const getSelectedOption = (questionId, quesText) => {
    // console.log("selectedAnswers ", selectedAnswers);
    const activeGroup = activeCategory && activeCategory.group.title;
    const activeCate = activeCategory && activeCategory.category.title;

    const selectedCheckBoxes = selectedAnswers[activeGroup];
    const currentOptionsObj =
      (selectedCheckBoxes && selectedCheckBoxes[quesText]) || {};

    // Get selected keys of checkbox
    // const selectedArr = currentOptionsObj && currentOptionsObj[quesText];
    const selectedArr = currentOptionsObj && currentOptionsObj[questionId];
    return [selectedArr] || [];
  };

  return (
    <div className="m-5 px-3">
      <label className="block tracking-wide text-gray-700 text-sm font-semibold pb-3 text-left">
        * Answer the following questions for the selected project
      </label>
      {queSectionsData.map((question) => {
        return (
          <div>
            <div>
              <label className="block text-left font-bold text-blue-600 mt-3 pb-1 border-b border-slate-100">
                {question.id.toString().toUpperCase()}
              </label>
              <label className="flex text-gray-500 text-sm text-left pt-3 pb-4">
                {question.definition}
              </label>
              <label className="block tracking-wide font-semibold my-3 text-left">
                {question.title.replace(
                  `${REPLACE_BY_GROUPNAME}`,
                  `${activeGroupName}`
                )}
              </label>
            </div>
            <div>
              {/* Form checkboxes based on options available for answers */}
              {answerOptionsData.map((questText) => {
                return (
                  <div
                    className={`text-left flex flex-row items-center text-gray-700 text-sm px-2 hover:bg-slate-50 hover:font-semibold ${isDisable && "cursor-not-allowed"
                      }`}
                  >
                    <label className="w-5/12 mr-5">{questText}</label>
                    <div className="flex justify-start w-full my-2">
                      {question.answers.map((opt) => {
                        return question.feedbackType === "slider" ? (
                          <div className="py-1">
                            <Slider
                              handleChange={(e) =>
                                onSliderChange(e, question.id, questText)
                              }
                              selectedValue={
                                getSelectedOption(question.id, questText)[0]
                              }
                              isDisable={isDisable}
                            />
                          </div>
                        ) : (
                          <Checkbox
                            options={[
                              { id: opt, title: opt.toString().toUpperCase() },
                            ]}
                            selectedOption={
                              getSelectedOption(question.id, questText) || []
                            }
                            onChange={(e) =>
                              onCheckBoxChange(e, question.id, questText)
                            }
                          />
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default FeedbackQuestion;
