import Header from "../../components/User/Header";
import Signup from "../../components/User/Signup";
let bgImage = require("../../images/bgImage.jpg");

const signupBlock = () => {
  return (
    <>
      <Header
        heading="Signup to create an account"
        paragraph="Already have an account? "
        linkName="Login"
        linkUrl="/"
      />
      <Signup />
    </>
  );
};

export default function SignupPage() {
  return (
    <div className="lg:flex w-full">
      <div className="hidden lg:flex bg-indigo-100 flex-1 h-screen">
        <img
          src={bgImage}
          className="w-full h-full"
          alt="introduction page image"
        />
      </div>
      <div className="lg:w-6/12 xl:h-screen flex items-top justify-center">
        <div className="px-12 sm:px-24 md:px-48 lg:px-12 xl:px-20 xl:max-w-2xl w-full">
          {signupBlock()}
        </div>
      </div>
    </div>
  );
}
