import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ERROR_PRIVACY_POLICY } from "../../constants";
import { signup_api } from "../../constants/api";

import { signupFields } from "../../constants/formFields";
import Spinner from "../common/Spinner";
import FormAction from "./FormAction";
import Input from "./Input";
import { PolicyCheck } from "./PolicyCheck";

const fields = signupFields;
let fieldsState = {};

fields.forEach((field) => (fieldsState[field.id] = ""));

export default function Signup() {
  const [signupState, setSignupState] = useState(fieldsState);
  const [errorMsg, setError] = useState(null);
  const [successMsg, setSuccess] = useState(null);
  const [isloading, setLoading] = useState(false);
  const [isPolicyAccepted, setPolicyAccepted] = useState(false);

  const navigate = useNavigate();
  const navigateToLogin = () => navigate("/");

  const handleChange = (e) => {
    setSignupState({ ...signupState, [e.target.id]: e.target.value });
  };

  const validateInput = (e) => {
    const field = e.target;
    const matchField = signupState[field.isMatchField];

    if (matchField && matchField !== signupState[e.target.id]) {
      setError("Password and Confirm Password does not match.");
    }
  };

  // Toggle privacy-policy checkbox
  const handleOnPloicyCheckChange = (value) => {
    setPolicyAccepted(!isPolicyAccepted);
    if (!isPolicyAccepted) {
      setError("");
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isPolicyAccepted) {
      createAccount();
    } else {
      setError(ERROR_PRIVACY_POLICY);
    }

  };

  // Return only required fields needed to backend api
  const getValidFields = (data) => {
    // Remove 'confirmPassword' filed from object
    const { confirmPassword, ...newObj } = data;
    return newObj;
  };

  const isPassMatch = (pass, confPass) => {
    if (pass !== confPass) {
      setError("Password and ConfirmPassword does't match.");
      return false;
    }
    setError(null);
    return true;
  };

  //handle Signup API Integration here
  const createAccount = () => {
    // If pass and confirm pass does't match
    if (!isPassMatch(signupState["password"], signupState["confirmPassword"])) {
      return;
    }

    const reqData = getValidFields(signupState);
    console.log("reqData", reqData);

    setLoading(true);

    fetch(signup_api, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(reqData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code && data.message) {
          setError(data.message);
          setLoading(false);
        } else {
          //API Success from LoginRadius Login API
          console.log("User creted successfully....");
          setSuccess(
            "Thank you. Your account has been created successfully and is under verification. Once Admin has approved your account, you will get a confirmation email and then you can login to the platform."
          );
          // when the component is mounted, the alert is displayed for 3 seconds
          setTimeout(() => {
            setSuccess(null);
            navigateToLogin();
          }, 15000);
          setLoading(false);
        }
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
        console.log(error);
      });
  };

  const showErrorMsg = () => {
    if (!errorMsg) return;

    return (
      <div
        className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 mt-4 rounded relative"
        role="alert"
      >
        <div className="w-11/12">
          <strong className="font-bold">Error: </strong>
          <span className="block sm:inline">{errorMsg}</span>
        </div>
        <span
          className="absolute top-0 bottom-0 right-0 px-4 py-3"
          onClick={() => setError(null)}
        >
          <svg
            className="fill-current h-6 w-6 text-red-500"
            role="button"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <title>Close</title>
            <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
          </svg>
        </span>
      </div>
    );
  };

  const showSuccessMsg = () => {
    if (!successMsg) return;

    return (
      <div
        className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 mt-4 rounded relative"
        role="alert"
      >
        <div className="w-11/12 text-left">
          <span className="block sm:inline">{'successMsg'}</span>
        </div>
        <div>
          <span
            className="absolute top-0 bottom-0 right-0 px-4 py-3"
            onClick={() => setSuccess(null)}
          >
            <svg
              className="fill-current h-6 w-6 text-green-500"
              role="button"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <title>Close</title>
              <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
            </svg>
          </span>
        </div>
      </div>
    );
  };

  return (
    <form className="mt-2 space-y-6" onSubmit={handleSubmit}>
      <div className="" tabIndex={0}>
        {isloading && (
          <div className="flex h-screen absolute -top-10 left-1/2 z-50">
            <Spinner />
          </div>
        )}
        {fields.map((field) => (
          <Input
            key={field.id}
            handleChange={handleChange}
            value={signupState[field.id]}
            labelText={field.labelText}
            labelFor={field.labelFor}
            id={field.id}
            name={field.name}
            type={field.type}
            isRequired={field.isRequired}
            placeholder={field.placeholder}
          />
        ))}
        <PolicyCheck
          btnText="By signing up, I accept the"
          isChecked={isPolicyAccepted}
          handleOnPloicyCheckChange={handleOnPloicyCheckChange}
        />
        {showErrorMsg()}
        {showSuccessMsg()}
        <FormAction handleSubmit={handleSubmit} text="Signup" />
      </div>
    </form>
  );
}
