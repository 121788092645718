import { useEffect, useState } from "react";
import {
  BTN_CONTINUE,
  BTN_EDIT,
  BTN_START,
  LBL_NOT_STARTED,
  LBL_PARTIAL,
  LBL_SUBMITTED,
  UPDATE_STATUS,
} from "../../constants";
import { themeDefault } from "../../constants/themeSettings";
import { getCategories, getGroups } from "../../service";
import Collapse from "../common/Collapse";
import Multiselect from "../common/MultiSelect";
import Tags from "../common/Tags";
import TextArea from "../common/TextArea";
import FeedbackQuestion from "./FeedbackQuestion";

const Category = (props) => {
  let {
    isDisable,
    feedbackStatus,
    data = [],
    selectedGroups = {},
    handleSelection,
    selectedCategory,
    handleNewCategory,
    userCategories,
    status,
    setCurrentGroupName,
  } = props;
  // const [selectedCategory, setSelectedCategory] = useState("");
  // console.log("Category props ", props);

  const [categoryListOptions, setCategoryListOptions] = useState([]);
  const [formStatus, setFormStatus] = useState(status);

  useEffect(() => {
    // Your code here
    fetchCategoriesList();
  }, []);

  const fetchCategoriesList = async () => {
    try {
      // Fetch history page data / api logic
      getGroups().then((data) => {
        setCategoryListOptions(getOptionsList(data));
      });
    } catch (err) {
      console.log("Error ", err);
    }
  };

  const getOptionsList = (group) => {
    let newArr = [];

    group.categories &&
      group.categories.map((category) => {
        newArr = [...newArr, category.title];
      });

    newArr = newArr.map((str, index) => ({
      label: str,
      value: str,
      key: index + 1,
    }));

    return newArr;
  };

  // Get selected keys of checkbox
  const selectedOption = Object.keys(selectedGroups).filter(
    (k) => selectedGroups[k] === true
  );

  const handleNewTag = (newCategory, group) => {
    const categoryList = (userCategories && userCategories[group.title]) || [];

    //Push new cate to list
    categoryList.push(newCategory.value);

    handleNewCategory({ ...userCategories, [group.title]: categoryList });
  };

  const removeNewTag = (removeObj, group) => {
    if (!removeObj) return;

    const categoryList = (userCategories && userCategories[group.title]) || [];

    // Remove selected item from list
    const index = categoryList.indexOf(removeObj.value);
    categoryList.splice(index, 1);

    handleNewCategory({ ...userCategories, [group.title]: categoryList });
  };

  const handleOnChange = (group) => {
    console.log("group", group);
    const categoryListArr = getListWithCutomCategories(group);
    handleSelection({ group: group, category: categoryListArr });
    setCurrentGroupName(group.name);
  };

  const onAnswerClick = (ans) => {
    return ans;
  };

  const getCategoriesForSelectedGroups = () => {
    let newArr = [];
    data &&
      data.map((group) => {
        if (selectedOption.includes(group.title)) {
          newArr = [...newArr, group];
        }
      });

    return newArr;
  };

  // TODO: Remove this code(and related) once everything works as per requirement
  const TopicRelatedCategoriesForSelectedGroups = () => {
    let newArr = [];
    data.groups &&
      data.groups.map((group) => {
        if (selectedOption.includes(group.title)) {
          newArr = [...newArr, group];
        }
      });
    return newArr;
  };

  const getListWithCutomCategories = (group) => {
    let newArr = [];

    // Get custom categories from formData
    const userCategoriesList =
      (userCategories && userCategories[group.title]) || [];

    newArr = newArr.concat(userCategoriesList);
    return newArr;
  };

  const getSelectedList = (group) => {
    let categoryArr = getListWithCutomCategories(group);
    categoryArr = categoryArr.map((str, index) => ({
      label: str,
      value: str,
      key: index + 1,
    }));
    return categoryArr;
  };

  const showStatusLabel = (group) => {
    const status =
      (feedbackStatus && feedbackStatus[group.title]) || LBL_NOT_STARTED;

    const statusStyle =
      status === LBL_SUBMITTED
        ? themeDefault.btn_green
        : themeDefault.btn_slate;

    const feedback_btn_name =
      status === LBL_NOT_STARTED
        ? BTN_START
        : status === LBL_PARTIAL
        ? BTN_CONTINUE
        : BTN_EDIT;

    return (
      <div className="flex justify-center">
        <span
          className={`w-45 h-8 basis-[19.2857143%] py-[6px] items-center justify-center text-sm font-medium rounded-lg hover:opacity-80 hover:font-semibold ${statusStyle}`}
        >
          {status}
        </span>

        <button
          className="w-45 h-8 basis-[19.2857143%] ml-12 bg-transparent  text-sm font-medium border border-slate-500 hover:bg-blue-700 hover:text-white hover:border-transparent rounded-lg"
          onClick={() => handleOnChange(group)}
        >
          {feedback_btn_name}
        </button>
      </div>
    );
    return (
      <span className={`p-1.5 rounded-md ${statusStyle}`}>{"status"}</span>
    );
  };

  return (
    <div>
      {getCategoriesForSelectedGroups().map((group) => {
        return (
          <div className="py-5">
            <div className="pb-5">
              <label className="block tracking-wide text-gray-700 text-sm font-semibold mb-2 text-left">
                What are relevant <b>{group.title}</b> for your project ?
              </label>
              {/* <span className="grid gap-4 content-start">
                <Tags
                  forGroup
                  // options={getOptionsList(group)}
                  options={getListWithCutomCategories(group)}
                  handleNewTag={(newCategory) =>
                    handleNewTag(newCategory, group)
                  }
                />
              </span> */}
              <span className="grid gap-4 content-start">
                <Multiselect
                  isDisable={isDisable}
                  options={getOptionsList(group)}
                  selectedCategory={getSelectedList(group)}
                  handleNewTag={(newCategory) =>
                    handleNewTag(newCategory, group)
                  }
                  removeNewTag={(removedValue) => {
                    removeNewTag(removedValue, group);
                  }}
                />
              </span>
            </div>
            <div>{showStatusLabel(group)}</div>
          </div>
        );
      })}

      {/* <Collapse
        title={selectedCategory}
        childComponent={<FeedbackQuestion />}
      /> */}
    </div>
  );
};
export default Category;
