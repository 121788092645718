import { ADMIN_USER_ROLE } from "../../constants";
import { getUserRole } from "../../service/Auth";

/**
 * Simple is object check.
 * @param item
 * @returns {boolean}
 */
export function isObject(item) {
  return (
    item && typeof item === "object" && !Array.isArray(item) && item !== null
  );
}

/**
 * Deep merge two objects.
 * @param target
 * @param source
 */
export function mergeDeep(target, source) {
  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }
  return target;
}

export const sortArrByDate = (
  dataArr = [],
  dateField = "date",
  sortBy = "desc"
) => {
  // return empty array if there is no data found
  if (!dataArr || !dataArr.length) {
    return []
  }

  if (sortBy === "asc") {
    return dataArr.sort(
      (a, b) => new Date(a[dateField]) - new Date(b[dateField])
    );
  }
  // sort by date desc
  return dataArr.sort(
    (a, b) => new Date(b[dateField]) - new Date(a[dateField])
  );
};

export const sortArrByString = (
  dataArr = [],
  fieldName = "",
  sortBy = "desc"
) => {
  if (sortBy == "asc") {
    return dataArr.sort((a, b) =>
      a[fieldName].toUpperCase() > b[fieldName].toUpperCase() ? 1 : -1
    );
  } else {
    return dataArr.sort((a, b) =>
      a[fieldName].toUpperCase() > b[fieldName].toUpperCase() ? -1 : 1
    );
  }
};

// Return cropped string for given cropLength
export const cropLongString = (string, cropLength = 10) => {
  if (string.length > cropLength)
    return `${string.substring(0, cropLength)}`
  return string;
}

export const isAdminRole = () => {
  const userrole = getUserRole();
  const isAdminRole = userrole === ADMIN_USER_ROLE;
  return isAdminRole;
}
