export const FEEDBACK_ROUTE = "feedbacks";
export const FEEDBACK_ID_ROUTE = "feedback/:id";
export const ABOUT_ROUTE = "about";
export const LOGIN_ROUTE = "login";
export const LOGOUT_ROUTE = "login";
export const SIGNUP_ROUTE = "signup";

export const WIZARD_ROUTE = "wizard";
export const FORGOT_PASSWORD_ROUTE = "forgotpassword";

export const USER_REGISTRATION_ROUTE = "user-registration";

// NavBar or Top menu display names
export const FEEDBACK_MENU = { id: "feedback", title: "Previous maps" };
export const ABOUT_MENU = { id: "about", title: "About" };
export const LOGIN_MENU = { id: "login", title: "Login" };
export const LOGOUT_MENU = { id: "logout", title: "Logout" };

export const USER_REGISTRATION_MENU = { id: "userRegistration", title: "User Registration" };