// Use for Us cluster
// const BACKENDLESS_REST_DOMAIN = "api.backendless.com";

// Use for Europe cluster
// const BACKENDLESS_REST_DOMAIN = "eu-api.backendless.com";

//  ---------------- Backendless configuration ----------------

// stage backendless account for testing : swapg411
/*
const BACKENDLESS_REST_DOMAIN = "eu-api.backendless.com";
const BACKENDLESS_SUBDOMAIN = "liberalstew.backendless.app";
const APP_ID = "64749631-B35B-EF09-FF52-0F199EEB7900";
const REST_API_KEY = "DCCBEA0D-CC62-44D2-814D-8EB9FC6FFF12";
*/

// Production backendless app
const BACKENDLESS_REST_DOMAIN = "eu-api.backendless.com";
const BACKENDLESS_SUBDOMAIN = "smileylaborer.backendless.app";
const APP_ID = "0F787B23-F0CB-1A29-FFB9-8D41BDE6F000";
const REST_API_KEY = "7357FC5F-3D18-45B6-8883-ADE307230504";

// Add url of privacy policy pdf
export const PRIVACY_POLICY_URL = "https://www.sportgovernance-eoceuoffice.eu/privacy-policy-rings-tool";

// domain to download file. Use it as per cluster used while creating app/ or can check
const FILE_DOWNLOAD_DOMAIN_NORTHAME = "backendlessappcontent.com"; // North American cluster
const FILE_DOWNLOAD_DOMAIN_EUROPE = "eu.backendlessappcontent.com"; // European Union cluster
const FOLDER_ROOT = "feedback_actions_list"; // This is folder name where all files are stored on Backendless under files section
const GROUPS_PATH = `${FOLDER_ROOT}/stakeholders`;
const ACTIONS_PATH = `${FOLDER_ROOT}/factors`;

//  ---------------- Backendless configuration ----------------

//Vairable to store user data in local storage
export const USER_STORAGE = "user_info";

export const BASE_API = `https://${BACKENDLESS_REST_DOMAIN}/${APP_ID}/${REST_API_KEY}`;

export const login_api = `https://${BACKENDLESS_SUBDOMAIN}/api/users/login`;
export const signup_api = `https://${BACKENDLESS_SUBDOMAIN}/api/users/register`;
export const forgotPassword_api = `https://${BACKENDLESS_SUBDOMAIN}/api/users/restorepassword/`;

export const topics_api = BASE_API + "/data/Topics?relationsDepth=2";
export const groups_api = BASE_API + "/data/Groups?relationsDepth=2";

/* Api req params
    {topic: 'string', group: 'json{}', feedback: 'json{}', status:'string' }
 */
export const answers_api = `${BASE_API}/data/Feedbacks`;
export const update_answers_api = (id) => `${answers_api}/${id}`;

export const get_answer_api = (id) => `${answers_api}/${id}`;

export const category_api = `${BASE_API}/data/Categories`;

export const users_api = `${BASE_API}/data/users`;
export const updateUser_api = `https://${BACKENDLESS_SUBDOMAIN}/api/services/userStatus/changeUserStatus`;

// return file Download url
// File url format:  "https://eu.backendlessappcontent.com/<application id>/<REST-API-key>/files/<path>/<file name>"
export const file_download_url_groups = (file_name) =>
  `https://${FILE_DOWNLOAD_DOMAIN_EUROPE}/${APP_ID}/${REST_API_KEY}/files/${GROUPS_PATH}/${encodeURI(
    file_name
  )}`;
export const file_download_url_actions = (file_name) =>
  `https://${FILE_DOWNLOAD_DOMAIN_EUROPE}/${APP_ID}/${REST_API_KEY}/files/${ACTIONS_PATH}/${encodeURI(
    file_name
  )}`;
