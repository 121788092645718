import { NavLink, useNavigate } from "react-router-dom";
import {
  ABOUT_MENU,
  ABOUT_ROUTE,
  FEEDBACK_MENU,
  FEEDBACK_ROUTE,
  LOGIN_MENU,
  LOGIN_ROUTE,
  LOGOUT_MENU,
  USER_REGISTRATION_MENU,
  USER_REGISTRATION_ROUTE,
} from "../../constants/Routes";
import ProfileMenu from "../common/ProfileMenu";
import { themeDefault } from "../../constants/themeSettings";
import { clearUser, getUser } from "../../service/Auth";
import { isAdminRole } from "../utility";

const activeClassName = "text-blue-700";

const nav_item_text_style =
  "flex px-3 py-1 ml-2 self-center text-base text-gray-800 rounded-md focus:outline-none hover:bg-blue-100 hover:text-blue-700";

const USER_MENU_ITEM = [
  {
    title: `${FEEDBACK_MENU.title}`,
    id: `${FEEDBACK_MENU.id}`,
    path: `${FEEDBACK_ROUTE}`,
  },
  /* {
    title: `${ABOUT_MENU.title}`,
    id: `${ABOUT_MENU.id}`,
    path: `${ABOUT_ROUTE}`,
  }, */
];

let ADMIN_ITEM = [
  {
    title: `${USER_REGISTRATION_MENU.title}`,
    id: `${USER_REGISTRATION_MENU.id}`,
    path: `${USER_REGISTRATION_ROUTE}`,
  },
];

// Add users all routes to admin routes
ADMIN_ITEM = USER_MENU_ITEM.concat(ADMIN_ITEM);

function NavBar() {
  const navigate = useNavigate();
  const navigateTo = (path) => navigate(path);

  const user = getUser();

  const MENU_ITEM = isAdminRole() ? ADMIN_ITEM : USER_MENU_ITEM;


  function onLogout() {
    clearUser();
    navigateTo("/");
  }

  return (
    <div>
      <nav className="flex justify-center space-x-4">
        {user &&
          MENU_ITEM.map((obj, index) => {
            return (
              <NavLink
                key={`${obj.title}-index`}
                to={obj.path}
                className={({ isActive }) =>
                  `${isActive ? activeClassName : ""} ${nav_item_text_style}`
                }
              >
                {obj.title}
              </NavLink>
            );
          })}
        {!user && (
          <NavLink
            to={`${LOGIN_ROUTE}`}
            className={({ isActive }) =>
              `${isActive ? activeClassName : ""} ${nav_item_text_style}`
            }
          >
            {`${LOGIN_MENU.title}`}
          </NavLink>
        )}
        {user && <ProfileMenu getUser={getUser} onLogout={onLogout} />}
      </nav>
    </div>
  );
}
export default NavBar;
