import React, { PureComponent } from "react";
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  ZAxis,
  ResponsiveContainer,
  Cell,
  Label,
  text,
  LabelList,
  Legend,
  Dot,
} from "recharts";
import ChartTooltip from "./ChartToolTip";
import "./style.css";
import ColorHash from "color-hash";
import {
  file_download_url_actions,
  file_download_url_groups,
} from "../../constants/api";
import { themeDefault } from "../../constants/themeSettings";
import { getMergedPdf } from "../utility/PdfMerge";
import Spinner from "../common/Spinner";
import { cropLongString } from "../utility";

const mobileView = false;

const AXIS_DOMAIN_RANGE = [0, 10];

const LEFT_QUADRANT_LABEL_X = 30;
const LEFT_QUADRANT_LABEL_Y = 30;
const RIGHT_QUADRANT_LABEL_X = 75;
const RIGHT_QUADRANT_LABEL_Y = 68;

const QUADRANT_LABEL_BG = {
  CRITICAL: "#ed3833", //red shade
  HIGH: "#f07839", // light red shade
  MODERATE: "#f1dd4a", //yellow shade
  LOW: "#73f440", //green shade
};

const X_AXIS_KEY = "interest";
const Y_AXIS_KEY = "power";
const X_AXIS_LABEL = "Interest";
const Y_AXIS_LABEL = "Power";

// This is used to form a downloadfile name
const ALINMENT_KEY = 'alignment'
const INFLUENCEABILITY_KEY = 'influenceability'

const quadrant1 = 'Monitor'
const quadrant2 = 'Keep Satisfied'
const quadrant3 = 'Keep Informed'
const quadrant4 = 'Manage Closely'

const AXIS_END_LBL_STYLE = {
  fontSize: "14px",
  fontWeight: "bold",
};

const CustomLable = ({ x, y, value, intensity }) => {
  return (
    <g>
      <text
        x={`${x + 0.5}%`}
        y={`${y + 1}%`}
        // font-weight="bold"
        font-size={themeDefault.font_l}
        fill={themeDefault.grayLight}
        textAnchor="middle"
        dominantBaseline="middle"
      >
        {value}
      </text>
    </g>
  );
};

const CustomizedShape = (props) => {
  const { cx, cy, fill, title, node } = props;

  let offsetLeft = 0;
  let offsetTop = 0;

  let titleTxt = title;

  // If text start at end of screen : x-right
  if (cx + 350 > window.innerWidth) {
    let titleStringLen = 11;
    titleTxt = `${cropLongString(title, titleStringLen)}...`;
    offsetLeft -= title.length < 9 ? 40 : 80;
    offsetTop += 15;
  }

  // Keep point text above x-axis if it's 0
  if (node.y == 0 && node.x >= 0) {
    offsetTop = -25
  }

  // Keep point text below y-axis if it's 10
  if (node.x == 10 && node.y > 0) {
    offsetTop = +15
  }

  return (
    <g>
      <Dot cx={cx} cy={cy} r={5} fill={fill} />
      <g transform={`translate(${cx + offsetLeft},${cy + offsetTop})`}>
        <text
          x={10}
          y={0}
          dy={5}
          textAnchor="left"
          fill={fill}
          font-size="0.9em"
        >
          {titleTxt}
        </text>
      </g>
    </g>
  );
};

class BubbleChart extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showTooltip: false,
      event: {},
      isloading: false,
    };
  }

  onBubbleClickHandle = (item) => {
    this.tooltipItems = [];
    this.setState({ showTooltip: false });
  };

  onBubbleHoverHandle = (item, active, event, actionCreator) => {
    if (active) {
      if (actionCreator === "tooltip") {
        if (this.tooltipTimeout) clearTimeout(this.tooltipTimeout);
        this.setState({ showTooltip: true });
      } else if (actionCreator === "cell") {
        if (this.tooltipTimeout) clearTimeout(this.tooltipTimeout);
        this.tooltipItems = item;
        this.setState({ showTooltip: true, event });
      }
    } else {
      if (actionCreator === "cell") {
        if (this.tooltipTimeout) clearTimeout(this.tooltipTimeout);
        this.tooltipTimeout = setTimeout(() => {
          this.setState({ showTooltip: false });
        }, 200);
      }
      if (actionCreator === "tooltip") {
        this.setState({ showTooltip: false });
      }
    }
  };

  getPositionStyle = (pageX, pageY) => {
    let offsetLeft = 20;
    let offsetTop = 0;

    const top = pageY;
    const left = pageX;

    // if data point is at right most side of screen
    if (left + 450 > window.innerWidth) {
      // offsetLeft -= 20;
      offsetLeft -= 220;
    }
    // return { top: `${pageY-0}px`, left: `${pageX+150}px` };
    return { top: `${top + offsetTop}px`, left: `${left + offsetLeft}px` };
  };

  getDownloadFileName = (linkFor, item) => {
    let url = "";
    if (linkFor === "groups") {
      // url = file_download_url_groups(`${item.group.trim()}.pdf`);
      url = file_download_url_groups(`${"Group_sample"}.pdf`);
    } else {
      // url = file_download_url_actions(`${item.title.trim()}.pdf`);
      url = file_download_url_actions(`${"Action_sample"}.pdf`);
    }

    return url;
  };

  onCardClick = (linkFor, item) => {
    console.log("clicked card ", item);
    const fileDownloadUrl = this.getDownloadFileName(linkFor, item);
    window.open(fileDownloadUrl, "_blank"); //to open new page
    this.setState({ showTooltip: false });
  };

  // Return quadrant name based on dot position
  getQuadrantName = (item = {}) => {
    let quadName = ''

    const x = item[X_AXIS_KEY];
    const y = item[Y_AXIS_KEY];

    if (!x || !y) return quadName

    if (x <= 5 && y <= 5) {
      quadName = quadrant1 + ' ' + 'low_power low_interest'
    } else if (x <= 5 && y >= 5) {
      quadName = quadrant2 + ' ' + 'high_power low_interest'
    } else if (x >= 5 && y <= 5) {
      quadName = quadrant3 + ' ' + 'low_power high_interest'
    } else {
      quadName = quadrant4 + ' ' + 'high_power high_interest'
    }

    // check INFLUENCEABILITY_KEY and ALINMENT_KEY (low < 5 and high > 5)
    const factor_alignment = item[ALINMENT_KEY]
    const factor_influenceability = item[INFLUENCEABILITY_KEY]

    // For influenceability
    // keep space before attaching new string to fileName
    if (factor_influenceability <= 5) {
      quadName += ' ' + 'low_influenceability'
    } else {
      quadName += ' ' + 'high_influenceability'
    }

    // For alignment
    // keep space before attaching new string to fileName
    if (factor_alignment <= 5) {
      quadName += ' ' + 'low_alignment'
    } else {
      quadName += ' ' + 'high_alignment'
    }

    return quadName
  }

  // Click on link formed a download url's for files and merge the pdf's
  onCardMergeClick = async (item = {}) => {
    this.setState({ isloading: true });

    // Get quadrant name and group name to download resp pdf's
    const quadrantPdf = this.getQuadrantName(item);
    const groupPdf = item.group && item.group || '';

    let pdfUrlArr = [];
    const groupFileUrl = file_download_url_groups(`${groupPdf.toLowerCase()}.pdf`);
    const actionFileUrl = file_download_url_actions(`${quadrantPdf.toLowerCase()}.pdf`);

    pdfUrlArr = [groupFileUrl, actionFileUrl];
    const mergedPdfUrl = await getMergedPdf(pdfUrlArr);

    this.setState({ showTooltip: false, isloading: false });
  };

  getDivPagePosition = (el) => {
    const rect = el.getBoundingClientRect();
    const scrollLeft =
      window.pageXOffset || document.documentElement.scrollLeft;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
  };

  render() {
    const { metaData, metaData1 } = this.props;
    const { showTooltip, event, isloading } = this.state;

    var colorHash = new ColorHash();

    return (
      <div
        id="bubblechartId"
        onMouseLeave={() => {
          this.onBubbleHoverHandle({}, false, null, "tooltip");
        }}
        onMouseEnter={() => {
          this.onBubbleHoverHandle({}, false, null, "tooltip");
        }}
        onClick={() => {
          this.onBubbleHoverHandle({}, false, null, "tooltip");
        }}
      >
        {isloading && (
          <div className="h-screen absolute -top-10 left-1/2 z-50">
            <Spinner />
          </div>
        )}
        {!mobileView && showTooltip ? (
          <div
            className={"custom-tooltip-container"}
            id="graph-tooltip"
            style={{
              zIndex: "1",
              position: "absolute",
              ...this.getPositionStyle(
                event && event.pageX,
                event && event.pageY
              ),
            }}
            onMouseEnter={() => {
              this.onBubbleHoverHandle({}, true, null, "tooltip");
            }}
            onMouseLeave={() => {
              this.onBubbleHoverHandle({}, false, null, "tooltip");
            }}
          >
            <ChartTooltip
              data={this.tooltipItems ? [this.tooltipItems] : []}
              onCardClick={this.onCardClick}
              onCardMergeClick={this.onCardMergeClick}
            />
          </div>
        ) : null}
        <div>
          <ResponsiveContainer width="100%" minHeight="480px" minWidth="700px">
            <ScatterChart
              margin={{
                top: 20,
                right: 20,
                bottom: 30,
                left: 30,
              }}
            >
              <CartesianGrid strokeDasharray="4 4" />
              <XAxis
                name={X_AXIS_KEY}
                dataKey={X_AXIS_KEY}
                type="number"
                tickCount={3}
                domain={AXIS_DOMAIN_RANGE}
              >
                <Label
                  id="x_axis_lbl"
                  value={X_AXIS_LABEL}
                  offset={-10}
                  position="insideBottom"
                />
                <Label
                  id="axisLabel_x_right"
                  value={"High"}
                  position="insideBottomRight"
                  offset={-5}
                  style={AXIS_END_LBL_STYLE}
                />
              </XAxis>

              <YAxis
                name={Y_AXIS_KEY}
                dataKey={Y_AXIS_KEY}
                type="number"
                tickCount={3}
                domain={AXIS_DOMAIN_RANGE}
              >
                <Label
                  id="axisLabel_y_top"
                  value={"High"}
                  position="insideTopLeft"
                  style={AXIS_END_LBL_STYLE}
                />

                <Label value={Y_AXIS_LABEL} angle="-90" position="insideLeft" />

                <Label
                  id="axisLabel_xy_zero"
                  value={"Low"}
                  position="insideBottom"
                  offset={-25}
                  style={AXIS_END_LBL_STYLE}
                />

                <Label
                  content={
                    <CustomLable
                      id="quadrant_left_bottom"
                      x={LEFT_QUADRANT_LABEL_X}
                      y={RIGHT_QUADRANT_LABEL_Y}
                      value={"Monitor"}
                      intensity={"CRITICAL"}
                    />
                  }
                />
                <Label
                  content={
                    <CustomLable
                      id="quadrant_left_top"
                      x={LEFT_QUADRANT_LABEL_X}
                      y={LEFT_QUADRANT_LABEL_Y}
                      value={"Keep Satisfied"}
                      intensity={"HIGH"}
                    />
                  }
                />

                <Label
                  content={
                    <CustomLable
                      id="quadrant_right_bottom"
                      x={RIGHT_QUADRANT_LABEL_X}
                      y={RIGHT_QUADRANT_LABEL_Y}
                      value={"Keep Informed"}
                      intensity={"MODERATE"}
                    />
                  }
                />
                <Label
                  content={
                    <CustomLable
                      id="quadrant_right_top"
                      x={RIGHT_QUADRANT_LABEL_X}
                      y={LEFT_QUADRANT_LABEL_Y}
                      value={"Manage Closely"}
                      intensity={"LOW"}
                    />
                  }
                />
              </YAxis>
              {Object.entries(metaData1).map(([key, points]) => {
                return (
                  <Scatter
                    name={key}
                    data={points}
                    // fill={colorHash.hex(key)}
                    fill={"#2463ea"}
                    shape={<CustomizedShape key={key} />}
                    onMouseEnter={(item, i, e) => {
                      e.persist();
                      this.onBubbleHoverHandle(
                        item,
                        true,
                        /* { pageX: e.pageX, pageY: e.pageY }, */
                        {
                          pageX: e.nativeEvent.offsetX,
                          pageY: e.nativeEvent.offsetY,
                        },
                        "cell"
                      );
                    }}
                    onMouseLeave={() => {
                      this.onBubbleHoverHandle({}, false, null, "cell");
                    }}
                  />
                );
              })}
              {/* <Legend
                wrapperStyle={{ top: 0, right: 0 }}
                verticalAlign="top"
                align="center"
              /> */}
            </ScatterChart>
          </ResponsiveContainer>
        </div>
      </div>
    );
  }
}

export default BubbleChart;